import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import lightLargeLogo from '../assets/images/logo/rw-large-light.svg';
import darkLargeLogo from '../assets/images/logo/rw-large-dark.svg';
import lightSmallLogo from '../assets/images/logo/rw-small-light.svg';
import darkSmallLogo from '../assets/images/logo/rw-small-dark.svg';
import rwtvlogo from '../assets/images/logo/rwtv-logo.png';
import StoreContext from './Context';

export const Logo = ({ color, logo }) => {
  const [screenWidth, setScreenWidth] = useState(undefined);
  const [store, setStore] = useContext(StoreContext);

  const { isMenuOpen } = store.menu;

  const resetStore = () => {
    setStore({ menu: { isMenuOpen: false }, active: false });
  };

  useEffect(() => {
    if (window.innerWidth) {
      setScreenWidth(window.innerWidth);
    }
  }, []);

  const renderLogo = () => {
    if (logo === 'rwtv') {
      return rwtvlogo;
    }

    if (
      (screenWidth && screenWidth > 800 && isMenuOpen) ||
      (store.scrolledToWhite && screenWidth > 800)
    ) {
      return darkLargeLogo;
    }

    if (
      (screenWidth && screenWidth < 800 && isMenuOpen) ||
      (store.scrolledToWhite && screenWidth < 800)
    ) {
      return darkSmallLogo;
    }

    if (screenWidth && screenWidth > 800 && color === 'white') {
      return lightLargeLogo;
    }

    if (
      (screenWidth && screenWidth > 800 && color !== 'white') ||
      (store.scrolledToWhite && screenWidth > 800)
    ) {
      return darkLargeLogo;
    }

    if (screenWidth && screenWidth < 800 && color === 'white') {
      return lightSmallLogo;
    }

    if (
      (screenWidth && screenWidth < 800 && color !== 'white') ||
      (store.scrolledToWhite && screenWidth < 800)
    ) {
      return darkSmallLogo;
    }

    return 'Something is really bad written bruuhh';
  };

  return (
    <LogoContainer onClick={resetStore} logo={logo}>
      <Link to="/">
        <img src={renderLogo()} alt="logo" />
      </Link>
    </LogoContainer>
  );
};

const LogoContainer = styled.div`
  position: fixed;
  top: 25px;
  left: 25px;
  z-index: 99999;
  width: 156px;
  height: 16px;

  img {
    height: ${(logo) => (logo ? 20 : 16)}px;
  }
  @media (max-width: 800px) {
    top: 15px;
    left: 15px;
    max-width: 50px;
  }
`;
